import React, { useCallback, useEffect, useState } from "react";
import {
    Grid,
    Header,
    Divider,
    Table,
    Label,
    Button,
    Modal,
    Dropdown,
    Input,
    Pagination,
    Icon,
} from "semantic-ui-react";
import { useLocation, useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import * as actionCreators from "./actions";
import { initListData as initCategoryListData } from "./../Category/actions";
import * as utils from "@hitek-crm-utils";
import { useUserSetting } from "../../hooks/useUserSettingHook";

const MODULE_NAME = "Item_list_module";

const defaultSearch = { itemName: "", vendorCode: "", categories: [] };
function loadData(dispatch, state) {
    dispatch(
        actionCreators.initListData({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        })
    );
    dispatch(
        actionCreators.initListEstimationCost({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        })
    );
    dispatch(
        initCategoryListData({
            companyId: state.companyId,
            companyGroupId: state.companyGroupId,
        })
    );
}
export function ItemListContainer() {
    const [idForRemove, setIdForRemove] = useState(null);

    const { companyId, companyGroupId } = useParams();
    const [activPage, setActivPage] = useState(1);
    const [searchCategories, setSearchCategories] = useState([]);
    const [search, setSearch] = useState(defaultSearch);

    const [activeConfig, changeUSerSettinghandler] = useUserSetting(MODULE_NAME);

    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();

    const { itemStore, categoryStore } = useSelector((state) => {
        return {
            itemStore: state.itemList,
            categoryStore: state.categoryList,
        };
    });
    useEffect(() => {
        loadData(dispatch, { companyId, companyGroupId });
        setSearch(defaultSearch);
        setActivPage(1);

    }, [companyId, companyGroupId]);

    useEffect(() => {
        const newSearch = { ...defaultSearch };
        let searchParam = new URLSearchParams(location.search);

        for (var k of Object.keys(newSearch)) {
            if (!searchParam.has(k)) {
                continue;
            }
            if (k == 'categories') {
                const v = searchParam.get("categories").split(',');
                newSearch[k] = [];
                for (let _v of v) {
                    if (!isNaN(parseInt(_v))){
                        newSearch[k].push(parseInt(_v));
                    }
                }

            } else {
                newSearch[k] = searchParam.get(k);
            }
        }
        if (searchParam.has("categories") && !isNaN(parseInt(searchParam.get("categories")))) {
            setSearchCategories([parseInt(searchParam.get("categories"))]);
        } else {
            setSearchCategories([]);
        }
        setSearch(newSearch);
        setActivPage(1);
    }, [location]);

    useEffect(() => {
        if (itemStore.isShouldReload) {
            loadData(dispatch, { companyId, companyGroupId });
        }
    }, [companyId, companyGroupId, itemStore.isShouldReload]);

    function getDataForTable() {
        if (!itemStore || !Array.isArray(itemStore.itemListSummury)) return [];

        let res = itemStore.itemListSummury;

        if (search.categories.length > 0) {
            res = res.filter(
                (elem, i, arr) =>
                    Array.isArray(elem.categoriesId) &&
                    elem.categoriesId.some((ilem2) => search.categories.includes(ilem2))
            );
        }
        if (search.itemName.length > 2) {
            res = res.filter((x) => x.name.toLowerCase().indexOf(search.itemName) > -1);
        }
        if (search.vendorCode.length > 2) {
            res = res.filter(
                (x) => !!x.vendorCode && x.vendorCode.toLowerCase().indexOf(search.vendorCode) > -1
            );
        }

        return res;
    }

    function getItemById(id) {
        if (!itemStore) return {};

        return itemStore.itemListSummury.find((x) => x.id == id) || {};
    }

    const handleCloseopenDeleteModal = useCallback(
        (obj, button) => {
            if (button.positive) {
                dispatch(
                    actionCreators.deleteItem({
                        id: idForRemove,
                        companyId,
                        companyGroupId,
                    })
                );
            }
            setIdForRemove(null);
        },
        [companyId, companyGroupId, idForRemove]
    );
    const handleClickSettingButton = useCallback(
        (e) => {
            changeUSerSettinghandler();
        },
        [changeUSerSettinghandler]
    );

    const handleChangeSearch = useCallback((e, target) => {
        const { name, value } = target;


        const newSearch = { ...search };
        if (Array.isArray(value)) {
            newSearch[name] = value;

        } else {
            newSearch[name] = value.toLowerCase();
        }
        const urlSearch = [];
        for (let k of Object.keys(newSearch)) {
            if (!newSearch[k] || Array.isArray(newSearch[k]) && newSearch[k].length == 0) {
                continue;
            }
            if (Array.isArray(newSearch[k])) {
                urlSearch.push(k + "=" + newSearch[k].join(','));

            } else {
                urlSearch.push(k + "=" + newSearch[k]);
            }
        }
        history.replace({
            pathname: location.pathname,
            search: urlSearch.join("&"),
        });

    }, [search]);

    const handlePaginationChange = useCallback((e, target) => {
        setActivPage(target.activePage);
    }, []);

    let urlPrefix = "";
    if (companyGroupId) {
        urlPrefix = "/CompanyGroup/" + companyGroupId;
    }
    if (companyId) {
        urlPrefix = "/Company/" + companyId;
    }

    return (
        <Grid>
            <Grid.Row>
                <Grid.Column>
                    <Header size="huge">Товары</Header>
                </Grid.Column>
            </Grid.Row>
            <Divider />
            <Grid.Row>
                <Grid.Column>
                    <Button color="green" as={Link} to={urlPrefix + "/Item/New"}>
                        Добавить новый товар
                    </Button>
                </Grid.Column>
            </Grid.Row>
            <Grid.Row>
                <Grid.Column>
                    <Table>
                        <Table.Header>
                            <Table.Row>
                                <Table.HeaderCell>Id</Table.HeaderCell>
                                <Table.HeaderCell>Название</Table.HeaderCell>
                                <Table.HeaderCell>Категории</Table.HeaderCell>
                                <Table.HeaderCell>Артикул</Table.HeaderCell>
                                <Table.HeaderCell>Цена</Table.HeaderCell>
                                <Table.HeaderCell>Cебестоимость</Table.HeaderCell>
                                <Table.HeaderCell>Активно</Table.HeaderCell>

                                <Table.HeaderCell>Действия</Table.HeaderCell>
                            </Table.Row>
                            <Table.Row>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Input
                                        value={search.itemName}
                                        name="itemName"
                                        onChange={handleChangeSearch}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Dropdown
                                        loading={categoryStore.isCategoryListLoading}
                                        placeholder="Категория"
                                        selection
                                        search
                                        multiple
                                        clearable
                                        options={Array.from(categoryStore.categoryList, (x) => {
                                            return { text: x.fullName, value: x.id };
                                        })}
                                        value={search.categories}
                                        name='categories'
                                        onChange={handleChangeSearch}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    <Input
                                        value={search.vendorCode}
                                        name="vendorCode"
                                        onChange={handleChangeSearch}
                                    />
                                </Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>
                                <Table.HeaderCell></Table.HeaderCell>

                                <Table.HeaderCell></Table.HeaderCell>
                            </Table.Row>
                        </Table.Header>
                        <Table.Body>
                            {getDataForTable()
                                .filter(
                                    (x, index) =>
                                        index >= (activPage - 1) * activeConfig.countPerPage &&
                                        index < activPage * activeConfig.countPerPage
                                )
                                .map((item) => {
                                    let estimateCost = itemStore.itemListEstimateCost.find(
                                        (x) => x.id == item.id
                                    );
                                    if (!estimateCost) {
                                        estimateCost = "-";
                                    } else {
                                        estimateCost = utils.toFloatToString(
                                            estimateCost.estimateCost,
                                            2
                                        );
                                    }
                                    return (
                                        <Table.Row key={item.id}>
                                            <Table.Cell>{item.id}</Table.Cell>
                                            <Table.Cell>{item.name}</Table.Cell>
                                            <Table.Cell>
                                                {!!item.categoriesName &&
                                                    categoryStore.categoryList
                                                        .filter((x) =>
                                                            item.categoriesId.includes(x.id)
                                                        )
                                                        .map((cat) => {
                                                            return (
                                                                <Label key={cat.id}>
                                                                    {cat.fullName}
                                                                </Label>
                                                            );
                                                        })}
                                            </Table.Cell>
                                            <Table.Cell>{item.vendorCode}</Table.Cell>
                                            <Table.Cell>{item.price} р.</Table.Cell>
                                            <Table.Cell>
                                                {estimateCost} р. /{" "}
                                                {utils.toFloatToString(
                                                    (estimateCost / item.price) * 100
                                                )}
                                                %{" "}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.isActive ? (
                                                    <Icon name="check" />
                                                ) : (
                                                    <Icon name="ban" />
                                                )}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {((item.isCompanyGroup && companyGroupId) ||
                                                    !item.isCompanyGroup) && (
                                                        <>
                                                            <Button
                                                                as={Link}
                                                                to={
                                                                    urlPrefix +
                                                                    "/Item/" +
                                                                    item.id +
                                                                    "/Edit"
                                                                }
                                                                icon="edit"
                                                                content="Редактировать"
                                                            />
                                                            <Button
                                                                onClick={() => {
                                                                    setIdForRemove(item.id);
                                                                }}
                                                                icon="remove"
                                                                color="red"
                                                                content="Удалить"
                                                            />
                                                        </>
                                                    )}
                                            </Table.Cell>
                                        </Table.Row>
                                    );
                                })}
                        </Table.Body>
                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell colSpan="2" textAlign="center">
                                    Отображать по <strong>{activeConfig.countPerPage}</strong> строк{" "}
                                    <Button icon="setting" onClick={handleClickSettingButton} />
                                </Table.HeaderCell>
                                <Table.HeaderCell colSpan="100" textAlign="center">
                                    <Pagination
                                        activePage={activPage}
                                        boundaryRange={2}
                                        onPageChange={handlePaginationChange}
                                        size="mini"
                                        siblingRange={2}
                                        totalPages={utils.toCorrectFloat(
                                            getDataForTable().length / activeConfig.countPerPage +
                                            0.49,
                                            0
                                        )}
                                    />
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </Table>
                </Grid.Column>
            </Grid.Row>

            <Modal open={!!idForRemove} onClose={handleCloseopenDeleteModal} centered={true}>
                <Modal.Header>Удалить товар "{getItemById(idForRemove).name}"</Modal.Header>
                <Modal.Content>Уверены,что хотите удалить товар?</Modal.Content>
                <Modal.Actions>
                    <Button negative onClick={handleCloseopenDeleteModal}>
                        Нет
                    </Button>
                    <Button
                        positive
                        onClick={handleCloseopenDeleteModal}
                        labelPosition="right"
                        icon="checkmark"
                        content="Да"
                    />
                </Modal.Actions>
            </Modal>
        </Grid>
    );
}
