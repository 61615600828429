import React, { Component } from "react";
import {
    Grid,
    Header,
    Button,
    Divider,
    Form,
    Message,
    Table,
    Icon,
    Popup,
    Label,
    Modal,
    Tab,
    Input,
    Dimmer,
    Loader,
} from "semantic-ui-react";
import * as actionCreators from "./actions";
import { initListData as initPaymentMethodList } from "./../PaymentMethod/actions";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import * as utils from "@hitek-crm-utils";

export class OrderDetail extends Component {
    constructor(props) {
        super(props);
        this.state = {
            companyId: this.props.match.params.companyId,
            orderId: this.props.match.params.orderId,
            isLoaded: false,
            id: "",
            name: "",
            orderPosition: [],
            orderTotalSumm: 0,
            orderTotalCount: 0,
            orderDeliveryAddress: "",
            orderDeliveryAddressBuilding: "",
            orderDeliveryAddressApartmentNumber: "",
            orderDeliveryAddressLevel: "",
            orderDeliveryAddressEntrance: "",
            orderDeliveryPhone: "",
            orderDeliveryChange: undefined,
            orderPersons: undefined,
            orderNote: "",
            orderPhone: "",
            orderStatusId: "",
            orderStatusReason: "",
            tags: [],
            isChangingStatus: false,
            isOpenPayModal: false,
            pay: {},
            payOrderStatusId: null,
            externalPaymentMedhodId: null,
            externalInvoceInfoOpen: false,
            externalSystemInfo: null
        };

        this.handleSaveOrder = this.handleSaveOrder.bind(this);
        this.handleChangeOrderStatusId = this.handleChangeOrderStatusId.bind(this);
        this.handleChangeOrderStatusReason = this.handleChangeOrderStatusReason.bind(this);
        this.handleCancleIsChangingStatus = this.handleCancleIsChangingStatus.bind(this);
        this.handleIsChangingStatus = this.handleIsChangingStatus.bind(this);

        this.handleOnPrint = this.handleOnPrint.bind(this);
        this.handleOnKitchen = this.handleOnKitchen.bind(this);
        this.handleOnPrintUsedStockItem = this.handleOnPrintUsedStockItem.bind(this);
        this.handleOpenModalPay = this.handleOpenModalPay.bind(this);
        this.handleCloseModalPay = this.handleCloseModalPay.bind(this);

        this.handlerOnChangePay = this.handlerOnChangePay.bind(this);
        this.handlerOnChangePayFullAmount = this.handlerOnChangePayFullAmount.bind(this);
        this.handleChangePayOrderStatusId = this.handleChangePayOrderStatusId.bind(this);
        this.handlerSavePay = this.handlerSavePay.bind(this);
        this.handlerSaveExternalPay = this.handlerSaveExternalPay.bind(this);
        this.handleCloseModalInvoceInfo = this.handleCloseModalInvoceInfo.bind(this);
        this.handleCloseModalExternalPay = this.handleCloseModalExternalPay.bind(this);
        this.handleOnCheckInvoice = this.handleOnCheckInvoice.bind(this);
        this.handleOnConfirmPayment = this.handleOnConfirmPayment.bind(this);
        this.handleOnRejectPayment = this.handleOnRejectPayment.bind(this);

        this.initData({
            companyId: this.state.companyId,
            orderId: this.state.orderId,
        });
    }

    getCompanyName() {
        let companies = this.props.companies.companyList;
        if (!Array.isArray(companies)) return null;
        let company = companies.find((x) => x.id == this.state.companyId);
        if (!!company) {
            return company.name;
        }
        return null;
    }

    componentWillReceiveProps(nextProps) {
        if (!this.state.isLoaded && nextProps.order.isLoaded) {
            const orderInfo = nextProps.order.orderDetail;
            this.calcTotals(nextProps.order.orderDetail.items);
            this.setState({
                orderPhone: orderInfo.phone,
                orderNote: orderInfo.note,
                orderExternalNote: orderInfo.externalNote,
                externalSystemInfo: orderInfo.externalSystemInfo,
                orderDeliveryAddress: orderInfo.deliveryAddress,
                orderDeliveryAddressBuilding: orderInfo.deliveryAddressBuilding,
                orderDeliveryAddressApartmentNumber: orderInfo.deliveryAddressApartmentNumber,
                orderDeliveryAddressLevel: orderInfo.deliveryAddressLevel,
                orderDeliveryAddressEntrance: orderInfo.deliveryAddressEntrance,
                orderDeliveryPhone: orderInfo.deliveryPhone,
                orderDeliveryChange: orderInfo.deliveryChange,
                orderStatusId: orderInfo.statusId,
                orderPersons: orderInfo.persons,
                orderStatusReason: "",
                isLoaded: true,
                tags: orderInfo.tags && Array.isArray(orderInfo.tags) ? orderInfo.tags : [],
            });
        }

        if (
            this.props.match.params.companyId != nextProps.match.params.companyId ||
            this.props.match.params.orderId != nextProps.match.params.orderId
        ) {
            this.setState({
                companyId: nextProps.match.params.companyId,
                orderId: nextProps.match.params.orderId,
                isLoaded: false,
            });
            this.initData({
                companyId: nextProps.match.params.companyId,
                orderId: nextProps.match.params.orderId,
            });
        }
        if (!nextProps.order.isChangingStatus && nextProps.order.isChangedStatus) {
            this.props.clearStore();
            this.initData({
                companyId: this.state.companyId,
                orderId: this.state.orderId,
            });
            this.setState({
                isChangingStatus: false,
                isOpenPayModal: false,
                pay: {},
                payOrderStatusId: null,
                orderStatusReason: "",

            });
        }
    }
    componentWillUnmount() {
        this.props.clearStore();
    }

    initData(params) {
        this.props.initOrderDetailData({
            companyId: params.companyId,
            orderId: params.orderId,
        });

        this.props.initItemHistory({
            companyId: params.companyId,
            orderId: params.orderId,
        });

        this.props.initStatusOrderList({ companyId: params.companyId });
        this.props.initTagList({
            companyId: params.companyId,
        });
        this.props.initPaymentMethodList({
            companyId: params.companyId,
        });
        this.props.initOrderPayment({
            companyId: params.companyId,
            orderId: params.orderId,
        });
        this.props.initPrimeCost({
            companyId: params.companyId,
            orderId: params.orderId,
        });
    }

    calcTotals(arr) {
        if (!Array.isArray(arr)) return;

        let totalSumm = 0;
        let totalCount = 0;

        let iterator = arr.values();
        let obj = iterator.next();
        let value = null;
        while (obj.done !== true) {
            value = obj.value;
            totalSumm += value.price * value.count;
            totalCount += value.count;

            obj = iterator.next();
        }

        this.setState({
            orderTotalSumm: totalSumm,
            orderTotalCount: totalCount,
        });
    }

    getTagByTagId(tagId) {
        let o = this.props.tagList.tagList.find((x) => x.id === tagId);
        if (o) {
            return o.name;
        }
        return tagId;
    }
    getColorTagByTagId(tagId) {
        let o = this.props.tagList.tagList.find((x) => x.id === tagId);
        if (o) {
            return o.color;
        }
        return undefined;
    }

    handleChangeOrderStatusReason(e, target) {
        this.setState({ orderStatusReason: target.value });
    }
    handleChangeOrderStatusId(e, target) {
        this.setState({ orderStatusId: target.value });
    }
    handleSaveOrder(e) {
        e.preventDefault();
        this.props.saveOrderChangeStatus({
            orderId: this.state.orderId,
            companyId: this.state.companyId,
            orderStatusId: this.state.orderStatusId,
            orderStatusReason: this.state.orderStatusReason
        });
    }

    handleOnPrint() {
        this.props.printPreBill({
            companyId: this.state.companyId,
            orderId: this.state.orderId,
        });
    }

    handleOnKitchen() {
        this.props.printKitchen({
            companyId: this.state.companyId,
            orderId: this.state.orderId,
        });
    }
    handleOnPrintUsedStockItem() {
        this.props.printUsedStockItems({
            companyId: this.state.companyId,
            orderId: this.state.orderId,
        });
    }
    handleIsChangingStatus() {
        this.setState({
            isChangingStatus: true,
        });
    }
    handleCancleIsChangingStatus() {
        this.setState({
            isChangingStatus: false,
            orderStatusId: this.props.order.orderDetail.statusId,
            orderStatusReason: ""
        });
    }

    handleOpenModalPay() {
        this.setState({
            isOpenPayModal: true,
        });
    }
    handleCloseModalPay() {
        this.setState({
            isOpenPayModal: false,
        });
    }

    handlerOnChangePay(e, data) {
        const paymentId = data["data-payment-id"];
        const pattern = /^\d+([.]\d{0,2})?$/;
        const { value } = data;
        if (!pattern.test(value) && value !== "") {
            e.preventDefault();
            return;
        }

        let v = value;
        if (value.length > 1 && value[0] === "0" && value[1] !== "," && value[1] !== ".") {
            v = value.substr(1);
        }

        this.setState((state) => {
            return { ...state, pay: { ...state.pay, [paymentId]: v } };
        });
    }

    handlerOnChangePayFullAmount(e, data) {
        const paymentId = data["data-payment-id"];
        const v = this.calcReuiredForPay();
        this.setState((state) => {
            return { ...state, pay: { [paymentId]: v } };
        });
    }

    handleChangePayOrderStatusId(e, target) {
        this.setState({ payOrderStatusId: target.value });
    }

    calcSurrender(payment, total) {
        const surrender =
            payment.reduce((a, b) => {
                return a + parseFloat(b);
            }, 0) - total;
        return -surrender;
    }

    calcReuiredForPay() {
        const amount = this.props.order.orderDetail.amount;
        const payed = Array.from(this.props.order.orderPayment, (x) =>
            (!x.isRejected && x.isComplited) ? x.amount : 0
        ).reduce((a, b) => a + b, 0);
        return utils.toCorrectFloat(amount - payed);
    }
    handlerSavePay(e) {
        const param = {
            orderId: this.state.orderId,
            companyId: this.state.companyId,
            orderStatusId: this.state.payOrderStatusId,
            orderStatusReason: this.state.orderStatusReason,
            payment: Array.from(Object.keys(this.state.pay), (k) => {
                return { paymentMethodId: k, amount: this.state.pay[k] };
            }),
        };
        this.props.saveOrderPayment(param);
    }
    handlerSaveExternalPay(e) {
        const param = {
            orderId: this.state.orderId,
            companyId: this.state.companyId,
            orderStatusId: this.state.payOrderStatusId,
            orderStatusReason: this.state.orderStatusReason,

            payment: Array.from(Object.keys(this.state.pay), (k) => {
                return { paymentMethodId: k, amount: this.state.pay[k] };
            }),
        };
        this.props.saveOrderExternalPayment(param);
        this.setState({
            externalPaymentMedhodId: null,
            isOpenPayModal: false,
            externalInvoceInfoOpen: true,
        });
    }
    handleCloseModalExternalPay() {
        this.setState({ externalPaymentMedhodId: null });
    }
    handleCloseModalInvoceInfo(e) {
        this.setState({ externalInvoceInfoOpen: false });
    }

    handleOnCheckInvoice(e, data) {
        if (!confirm("подтвердить действие?")) {
            return;
        }
        const paymentId = data["data-payment-id"];
        const param = {
            orderId: this.state.orderId,
            companyId: this.state.companyId,
            paymentId: paymentId,
        };
        this.props.saveOrderExternalPaymentCheck(param);
    }

    handleOnConfirmPayment(e, data) {
        if (!confirm("подтвердить действие?")) {
            return;
        }
        const paymentId = data["data-payment-id"];
        const param = {
            orderId: this.state.orderId,
            companyId: this.state.companyId,
            paymentId: paymentId,
        };
        this.props.saveOrderPaymentConfirm(param);
    }
    handleOnRejectPayment(e, data) {
        if (!confirm("подтвердить действие?")) {
            return;
        }
        const paymentId = data["data-payment-id"];
        const param = {
            orderId: this.state.orderId,
            companyId: this.state.companyId,
            paymentId: paymentId,
        };
        this.props.saveOrderPaymentReject(param);
    }

    render() {
        const surrender = this.calcSurrender(
            Object.values(this.state.pay),
            this.calcReuiredForPay()
        );
        const propsorderDetail = this.props.order.orderDetail;
        return (
            <Grid>
                <Dimmer inverted active={this.props.order.isLoading}>
                    <Loader size="medium">Loading</Loader>
                </Dimmer>

                <Grid.Row columns="two">
                    <Grid.Column mobile={16} computer={10}>
                        <Header size="huge">
                            {" "}
                            Заказ{" "}
                            <Popup
                                trigger={<span>#</span>}
                                content={"Полный ID: " + propsorderDetail.id}
                                position="top left"
                            />{" "}
                            {propsorderDetail.internalId} , {"<"} {this.getCompanyName()} {">"}{" "}
                        </Header>
                    </Grid.Column>
                    <Grid.Column width={6}>
                        <Button
                            disabled={propsorderDetail.isClosed || !propsorderDetail.isEditable}
                            as={Link}
                            to={
                                "/Company/" +
                                this.state.companyId +
                                "/Order/" +
                                this.state.orderId +
                                "/Edit"
                            }
                            className="print-hide"
                            content="Редактировать"
                            positive
                        />
                    </Grid.Column>
                </Grid.Row>
                <Divider />

                <Grid.Row centered columns="two">
                    <Grid.Column mobile={16} computer={12}>
                        <Tab
                            panes={[
                                {
                                    menuItem: "Выбранные позиции",
                                    render: () => (
                                        <Tab.Pane key="tab1" active>
                                            <Table striped>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>
                                                            Название
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            Категория
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            <span className="print-hide">
                                                                Стоимость
                                                            </span>
                                                            <span className="print-only">
                                                                Стоим.
                                                            </span>
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>Кол.</Table.HeaderCell>
                                                        <Table.HeaderCell>Сумма</Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {propsorderDetail.items &&
                                                        propsorderDetail.items.map((pos) => {
                                                            const res = [];
                                                            res.push(<Table.Row
                                                                key={pos.id + "." + pos.categoryId}>
                                                                <Table.Cell>
                                                                    <Icon
                                                                        name="file outline"
                                                                        className="print-hide"
                                                                    />
                                                                    {pos.name}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {pos.categoryName}
                                                                </Table.Cell>
                                                                <Table.Cell>
                                                                    {utils.toFloatToString(
                                                                        pos.price
                                                                    )}{" "}
                                                                    р <br />{" "}
                                                                    {pos.discauntValue
                                                                        ? "-" +
                                                                        pos.discountDescription
                                                                        : ``}
                                                                </Table.Cell>
                                                                <Table.Cell>{pos.count}</Table.Cell>
                                                                <Table.Cell textAlign="right">
                                                                    {utils.toFloatToString(
                                                                        pos.amount ||
                                                                        pos.price * pos.count
                                                                    )}{" "}
                                                                    р
                                                                </Table.Cell>
                                                            </Table.Row>);
                                                            if (Array.isArray(pos.modificators)) {
                                                                for (var mod of pos.modificators) {
                                                                    res.push(
                                                                        <Table.Row
                                                                            key={pos.id + "." + pos.categoryId + '.' + mod.id}>
                                                                            <Table.Cell colSpan={2}>
                                                                                <Icon
                                                                                    name="caret right"
                                                                                    className="print-hide"
                                                                                /><Icon
                                                                                    name="caret right"
                                                                                    className="print-hide"
                                                                                />
                                                                                {mod.modificatorName}
                                                                            </Table.Cell>

                                                                            <Table.Cell>
                                                                                {utils.toFloatToString(
                                                                                    mod.price
                                                                                )}{" "}
                                                                                р <br />{" "}
                                                                                {pos.discauntValue
                                                                                    ? "-" +
                                                                                    pos.discountDescription
                                                                                    : ``}
                                                                            </Table.Cell>
                                                                            <Table.Cell>{mod.count}</Table.Cell>
                                                                            <Table.Cell textAlign="right">
                                                                                {utils.toFloatToString(
                                                                                    mod.amount ||
                                                                                    mod.price * mod.count
                                                                                )}{" "}
                                                                                р
                                                                            </Table.Cell>
                                                                        </Table.Row>

                                                                    );
                                                                }
                                                            }

                                                            return res;
                                                        })}
                                                </Table.Body>
                                                <Table.Footer>
                                                    {propsorderDetail.discountAmount > 0 && (
                                                        <Table.Row>
                                                            <Table.HeaderCell>
                                                                Скидка
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell colSpan="3">
                                                                {propsorderDetail.discountName}
                                                            </Table.HeaderCell>
                                                            <Table.HeaderCell textAlign="right">
                                                                -
                                                                {utils.toFloatToString(
                                                                    propsorderDetail.discountAmount
                                                                )}{" "}
                                                                р.
                                                            </Table.HeaderCell>
                                                        </Table.Row>
                                                    )}
                                                    <Table.Row>
                                                        <Table.HeaderCell colSpan="3">
                                                            Итого
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            {this.state.orderTotalCount}
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell textAlign="right">
                                                            {utils.toFloatToString(
                                                                propsorderDetail.amount
                                                            )}{" "}
                                                            р.
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Footer>
                                            </Table>
                                        </Tab.Pane>
                                    ),
                                },
                                {
                                    menuItem: "Детали Доставки",
                                    render: () => (
                                        <Tab.Pane key="tab1" active>
                                            <Header size="small">Заказ на дату</Header>
                                            <p>
                                                <Icon name="calendar" />
                                                {new Date(propsorderDetail.date).toLocaleString()}
                                            </p>
                                            <Header size="small">Контактный телефон</Header>
                                            <p>
                                                <Icon name="phone" />
                                                {this.state.orderPhone}
                                            </p>
                                            <p className="print-hide">
                                                <strong> {propsorderDetail.customerAlias}</strong>
                                                {propsorderDetail.operatorPhoneCallId
                                                    ? "(По звонку)"
                                                    : ""}
                                            </p>
                                            {!!this.state.orderDeliveryPhone &&
                                                <>
                                                    <Header size="small">Телефон доставки</Header>
                                                    <p>
                                                        <Icon name="phone" />
                                                        {this.state.orderDeliveryPhone}
                                                    </p>
                                                </>
                                            }
                                            <Header size="small">Адрес доставки</Header>
                                            <p>
                                                <Icon name="home" />
                                                {this.state.orderDeliveryAddress}{" "}
                                                {this.state.orderDeliveryAddressBuilding ? " д. " + this.state.orderDeliveryAddressBuilding : ""}
                                                {this.state.orderDeliveryAddressApartmentNumber ? " кв. " + this.state.orderDeliveryAddressApartmentNumber : ""}
                                                {this.state.orderDeliveryAddressLevel ? " эт. " + this.state.orderDeliveryAddressLevel : ""}
                                                {this.state.orderDeliveryAddressEntrance ? " под. " + this.state.orderDeliveryAddressEntrance : ""}
                                            </p>

                                            {!!this.state.orderDeliveryChange &&
                                                <>
                                                    <Header size="small">Сдача с </Header>
                                                    <p>
                                                        <Icon name="money" />
                                                        {utils.toFloatToString(this.state.orderDeliveryChange)}{" "}
                                                        р
                                                    </p>
                                                </>
                                            }
                                            {!!this.state.orderPersons &&
                                                <>
                                                    <Header size="small">Персон </Header>
                                                    <p>
                                                        {this.state.orderPersons}
                                                    </p>
                                                </>
                                            }

                                            <Header size="small">Примечание</Header>
                                            <p>{this.state.orderNote}</p>
                                            {!!this.state.externalSystemInfo &&
                                                <><Header size="small">Примечание из внешней системы</Header>
                                                    <p>{this.state.orderExternalNote}</p>
                                                </>
                                            }
                                            <Header size="small" className="print-hide">
                                                Теги
                                            </Header>
                                            <span className="print-hide">
                                                {this.state.tags.map((x) => (
                                                    <Label
                                                        key={x}
                                                        color={this.getColorTagByTagId(x)}>
                                                        {this.getTagByTagId(x)}
                                                    </Label>
                                                ))}
                                            </span>
                                        </Tab.Pane>
                                    ),
                                },
                                {
                                    menuItem: "Принятая оплата",
                                    render: () => (
                                        <Tab.Pane key="tab2">
                                            <Table striped>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Метод</Table.HeaderCell>
                                                        <Table.HeaderCell>Дата</Table.HeaderCell>
                                                        <Table.HeaderCell>Статус</Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            Совершен
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            Оператор
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell> Сумма</Table.HeaderCell>
                                                        <Table.HeaderCell> </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {this.props.order.orderPayment.map((pos) => {
                                                        function setIcon(isCompleted, isRejected) {
                                                            if (isRejected) {
                                                                <Icon name="ban" />
                                                            } else if (isCompleted) {
                                                                return <Icon name="check" />;
                                                            } else {
                                                                return <Icon name="clock" />
                                                            }
                                                        }
                                                        return <Table.Row
                                                            key={pos.id}
                                                            negative={pos.isRejected}
                                                            positive={pos.isComplited && !pos.isRejected}
                                                            warning={!pos.isComplited && !pos.isRejected}>
                                                            <Table.Cell>
                                                                {setIcon(pos.isComplited, pos.isRejected)}
                                                                {pos.paymentMethodName}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {pos.date.toLocaleString()}
                                                            </Table.Cell>
                                                            <Table.Cell>{pos.status}</Table.Cell>
                                                            <Table.Cell>
                                                                {pos.isComplited}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {pos.operatorAlias ||
                                                                    pos.operatorId}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {utils.toFloatToString(pos.amount)}{" "}
                                                                р
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {pos.isExternal && !pos.isComplited && !pos.isRejected && (
                                                                    <Button
                                                                        primary
                                                                        onClick={
                                                                            this
                                                                                .handleOnCheckInvoice
                                                                        }
                                                                        data-payment-id={pos.id}>
                                                                        Проверить
                                                                    </Button>
                                                                )}
                                                                {!pos.isRejected && (
                                                                    <Button
                                                                        negative
                                                                        onClick={
                                                                            this
                                                                                .handleOnRejectPayment
                                                                        }
                                                                        data-payment-id={pos.id}>
                                                                        <Icon name="repeat" /> Возврат
                                                                    </Button>
                                                                )}
                                                                {!pos.isExternal && !pos.isComplited && !pos.isRejected && (
                                                                    <Button
                                                                        color='yellow'

                                                                        onClick={
                                                                            this
                                                                                .handleOnConfirmPayment
                                                                        }
                                                                        data-payment-id={pos.id}>
                                                                        <Icon name='check' />  Подтвердить
                                                                    </Button>
                                                                )}
                                                            </Table.Cell>
                                                        </Table.Row>;
                                                    })}
                                                </Table.Body>
                                                <Table.Footer>
                                                    <Table.Row>
                                                        <Table.Cell colSpan="3">Итого</Table.Cell>
                                                        <Table.Cell>
                                                            {utils.toFloatToString(
                                                                Array.from(
                                                                    this.props.order.orderPayment,
                                                                    (x) =>
                                                                        x.isComplited ? x.amount : 0
                                                                ).reduce((a, b) => a + b, 0)
                                                            )}{" "}
                                                            р.
                                                        </Table.Cell>
                                                    </Table.Row>
                                                </Table.Footer>
                                            </Table>
                                        </Tab.Pane>
                                    ),
                                },
                                {
                                    menuItem: "Детали заказа",
                                    render: () => (
                                        <Tab.Pane key="tab3">
                                            <Header size="small">Заказ принял</Header>
                                            <p>
                                                <Icon name="user" />
                                                {propsorderDetail.operatorAlias}
                                            </p>
                                            <Header size="small">Заказ Принят</Header>
                                            <p>
                                                <Icon name="calendar" />
                                                {new Date(
                                                    propsorderDetail.creationDate
                                                ).toLocaleString()}
                                            </p>
                                            <Header size="small">Cебестоимость заказа</Header>
                                            <p>
                                                <Icon name="euro" />
                                                {this.props.order.orderPrimeCost
                                                    ? this.props.order.orderPrimeCost.primeCost +
                                                    " р."
                                                    : "-"}
                                            </p>

                                            {!!this.state.externalSystemInfo &&
                                                <>
                                                    <Header size="medium">
                                                        Внешняя система:
                                                    </Header>
                                                    <Header size="small">
                                                        Название
                                                    </Header>
                                                    <p>{this.state.externalSystemInfo.name}</p>
                                                    <Header size="small">
                                                        Id
                                                    </Header>
                                                    <p>{this.state.externalSystemInfo.id}</p>
                                                    <Header size="small">
                                                        Link
                                                    </Header>
                                                    <p><a href={this.state.externalSystemInfo.orderUrl} target="_blank" rel="noopener noreferrer">{this.state.externalSystemInfo.orderUrl}</a></p>
                                                </>
                                            }
                                            <Header size="small">
                                                Печать используемых продуктов{" "}
                                            </Header>
                                            <p>
                                                <Button
                                                    size="tiny"
                                                    icon="print"
                                                    onClick={
                                                        this.handleOnPrintUsedStockItem
                                                    }></Button>
                                            </p>
                                        </Tab.Pane>
                                    ),
                                },
                                {
                                    menuItem: "История заказа",
                                    render: () => (
                                        <Tab.Pane key="tab4">
                                            <Table striped>
                                                <Table.Header>
                                                    <Table.Row>
                                                        <Table.HeaderCell>Тип</Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            Операция
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>Дата</Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            Оператор
                                                        </Table.HeaderCell>
                                                        <Table.HeaderCell>
                                                            Значение
                                                        </Table.HeaderCell>
                                                    </Table.Row>
                                                </Table.Header>

                                                <Table.Body>
                                                    {this.props.order.orderHistory.map((pos, i) => (
                                                        <Table.Row key={i}>
                                                            <Table.Cell>
                                                                {" "}
                                                                {pos.typeElement}
                                                            </Table.Cell>
                                                            <Table.Cell> {pos.type}</Table.Cell>

                                                            <Table.Cell>
                                                                {new Date(
                                                                    pos.date
                                                                ).toLocaleString()}
                                                            </Table.Cell>
                                                            <Table.Cell>
                                                                {pos.employeeAlias ||
                                                                    pos.employeeId}
                                                            </Table.Cell>
                                                            <Table.Cell>{pos.newName}</Table.Cell>
                                                        </Table.Row>
                                                    ))}
                                                </Table.Body>
                                            </Table>
                                        </Tab.Pane>
                                    ),
                                },
                            ]}
                        />
                    </Grid.Column>
                    <Grid.Column mobile={16} computer={4}>
                        <Button
                            disabled={propsorderDetail.isClosed || !propsorderDetail.isEditable}
                            as={Link}
                            to={
                                "/Company/" +
                                this.state.companyId +
                                "/Order/" +
                                this.state.orderId +
                                "/Edit"
                            }
                            className="print-hide"
                            content="Редактировать"
                            positive
                        />

                        <Header size="small" className="print-hide">
                            Теги
                        </Header>

                        {(propsorderDetail.tags || []).map((x) => (
                            <Label key={x} color={this.getColorTagByTagId(x)}>
                                {this.getTagByTagId(x)}
                            </Label>
                        ))}
                        <Header size="small" className="print-hide">
                            Текущий статус
                        </Header>
                        <Label
                            key={"2"}
                            className="print-hide"
                            color={
                                this.props.orderStatusList.orderStatusList.find(
                                    (x) => x.id == propsorderDetail.statusId
                                )
                                    ? this.props.orderStatusList.orderStatusList.find(
                                        (x) => x.id == propsorderDetail.statusId
                                    ).color
                                    : "grey"
                            }
                            size="big">
                            {this.props.orderStatusList.orderStatusList.find(
                                (x) => x.id == propsorderDetail.statusId
                            )
                                ? this.props.orderStatusList.orderStatusList.find(
                                    (x) => x.id == propsorderDetail.statusId
                                ).name
                                : "-"}
                        </Label>

                        <Header key={"1"} size="small" className="print-hide">
                            Принять оплату
                        </Header>

                        <Button
                            disabled={propsorderDetail.isClosed}
                            className="print-hide"
                            color="green"
                            onClick={this.handleOpenModalPay}>
                            <Icon name="payment" />
                            Принять оплату
                        </Button>

                        <Button
                            disabled={propsorderDetail.isClosed}
                            className="print-hide"
                            primary
                            onClick={this.handleIsChangingStatus}>
                            <Icon name="edit" />
                            Изменить статус
                        </Button>

                        <Header key={"1"} size="small" className="print-hide">
                            Печать чеков
                        </Header>
                        <Button
                            className="print-hide"
                            icon="print"
                            content="Печать предчека"
                            primary
                            onClick={this.handleOnPrint}
                        />
                        <Button
                            className="print-hide"
                            icon="print"
                            content="Отправить на кухню"
                            color="teal"
                            onClick={this.handleOnKitchen}
                        />
                    </Grid.Column>

                    <Modal
                        size="mini"
                        open={this.state.isOpenPayModal}
                        onClose={this.handleCloseModalPay}>
                        <Modal.Header>Принять оплату</Modal.Header>
                        <Modal.Content>
                            <Form /*success= {this.props.operatorPhone.isSave} error={!!this.props.operatorPhone.error} loading={this.props.operatorPhone.isLoading} */
                            >
                                К оплате: {this.calcReuiredForPay()}
                                {this.props.paymentMethodList.paymentMethodListSummury
                                    .filter((x) => !x.externalPaymentMethodId)
                                    .map((p) => (
                                        <Form.Group key={p.id}>
                                            <Form.Field
                                                control={Input}
                                                label={p.name}
                                                data-payment-id={p.id}
                                                onChange={this.handlerOnChangePay}
                                                value={this.state.pay[p.id] || 0}
                                            />
                                            <Form.Field
                                                control={Button}
                                                icon
                                                content={<Icon name="check" />}
                                                color="green"
                                                label="вся сумма"
                                                data-payment-id={p.id}
                                                onClick={this.handlerOnChangePayFullAmount}
                                            />
                                        </Form.Group>
                                    ))}
                                {this.props.paymentMethodList.paymentMethodListSummury
                                    .filter((x) => !!x.externalPaymentMethodId)
                                    .map((p) => (
                                        <Form.Group key={p.id}>
                                            <Form.Field>{p.name}</Form.Field>
                                            <Form.Field
                                                control={Button}
                                                icon
                                                content={"Выставить оплату"}
                                                color="green"
                                                label="вся сумма"
                                                //data-payment-id={p.id}
                                                onClick={() => {
                                                    this.setState({
                                                        externalPaymentMedhodId: p.id,
                                                    });
                                                }}
                                            />
                                        </Form.Group>
                                    ))}
                                Требуется еще: <strong>{utils.toFloatToString(surrender)}</strong>
                                <Form.Group>
                                    <Form.Select
                                        label="Изменение статуса"
                                        loading={this.props.orderStatusList.isLoading}
                                        placeholder="Изменение статус"
                                        selection
                                        options={Array.from(
                                            this.props.orderStatusList.orderStatusList.filter(
                                                (x) => !x.isRemoved && !x.isTechnical
                                            ),
                                            (x) => {
                                                return {
                                                    text: x.name,
                                                    value: x.id,
                                                    label: {
                                                        empty: true,
                                                        circular: true,
                                                        color: x.color,
                                                    },
                                                };
                                            }
                                        )}
                                        value={this.state.payOrderStatusId}
                                        onChange={this.handleChangePayOrderStatusId}
                                    />
                                </Form.Group>
                                {(this.props.orderStatusList.orderStatusList.find(x => x.id == this.state.payOrderStatusId) || {}).isRequireReason && <Form.Group>
                                    <Form.TextArea
                                        label="Причина"
                                        placeholder="Указать причину"
                                        fluid
                                        value={this.state.orderStatusReason}
                                        onChange={this.handleChangeOrderStatusReason}
                                    />
                                </Form.Group>
                                }
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseModalPay}>
                                Отмена
                            </Button>

                            <Button
                                onClick={this.handlerSavePay}
                                color="green"
                                disabled={surrender < 0}>
                                Принять Оплату <Icon name="chevron right" />
                            </Button>
                        </Modal.Actions>
                    </Modal>

                    <Modal
                        open={this.state.isChangingStatus}
                        size="mini"
                        onClose={this.handleCancleIsChangingStatus}>
                        <Modal.Header>Изменить статус</Modal.Header>
                        <Modal.Content>
                            <Form /*success= {this.props.operatorPhone.isSave} error={!!this.props.operatorPhone.error} loading={this.props.operatorPhone.isLoading} */
                            >
                                <Form.Group>
                                    <Form.Select
                                        label="Установить новый статус"
                                        loading={this.props.orderStatusList.isLoading}
                                        placeholder="Установить новый статус"
                                        fluid
                                        selection
                                        options={Array.from(
                                            this.props.orderStatusList.orderStatusList.filter(
                                                (x) => !x.isRemoved && !x.isTechnical
                                            ),
                                            (x) => {
                                                return {
                                                    text: x.name,
                                                    value: x.id,
                                                    label: {
                                                        empty: true,
                                                        circular: true,
                                                        color: x.color,
                                                    },
                                                };
                                            }
                                        )}
                                        value={this.state.orderStatusId}
                                        onChange={this.handleChangeOrderStatusId}
                                    />
                                </Form.Group>
                                {(this.props.orderStatusList.orderStatusList.find(x => x.id == this.state.orderStatusId) || {}).isRequireReason && <Form.Group>
                                    <Form.TextArea
                                        label="Причина"
                                        placeholder="Указать причину"
                                        fluid
                                        value={this.state.orderStatusReason}
                                        onChange={this.handleChangeOrderStatusReason}
                                    />
                                </Form.Group>
                                }
                                <Message
                                    visible={
                                        !this.props.order.isChangingStatus &&
                                        this.props.order.isChangedStatusError
                                    }
                                    error
                                    header="Action Forbidden"
                                    content={this.props.order.isChangedStatusError}
                                />
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button
                                loading={this.props.order.isChangingStatus}
                                negative
                                disabled={
                                    !this.props.order.isChangingStatus &&
                                    this.props.order.isChangedStatus
                                }
                                onClick={this.handleCancleIsChangingStatus}>
                                Отмена
                            </Button>
                            <Button
                                loading={this.props.order.isChangingStatus}
                                positive
                                onClick={this.handleSaveOrder}>
                                Сохранить
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal
                        size="mini"
                        open={this.state.externalPaymentMedhodId}
                        onClose={this.handleCloseModalExternalPay}>
                        <Modal.Header>Выставить оплату </Modal.Header>
                        <Modal.Content>
                            <Form /*success= {this.props.operatorPhone.isSave} error={!!this.props.operatorPhone.error} loading={this.props.operatorPhone.isLoading} */
                            >
                                К оплате: {this.calcReuiredForPay()}
                                {this.props.paymentMethodList.paymentMethodListSummury
                                    .filter((x) => x.id == this.state.externalPaymentMedhodId)
                                    .map((p) => (
                                        <Form.Group key={p.id}>
                                            <Form.Field
                                                control={Input}
                                                label={p.name}
                                                data-payment-id={p.id}
                                                onChange={this.handlerOnChangePay}
                                                value={this.state.pay[p.id] || 0}
                                            />
                                            <Form.Field
                                                control={Button}
                                                icon
                                                content={<Icon name="check" />}
                                                color="green"
                                                label="вся сумма"
                                                data-payment-id={p.id}
                                                onClick={this.handlerOnChangePayFullAmount}
                                            />
                                        </Form.Group>
                                    ))}
                            </Form>
                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseModalExternalPay}>
                                Отмена
                            </Button>

                            <Button
                                onClick={this.handlerSaveExternalPay}
                                color="green"
                                disabled={surrender < 0}>
                                Выставить Оплату <Icon name="chevron right" />
                            </Button>
                        </Modal.Actions>
                    </Modal>
                    <Modal size="small" open={this.state.externalInvoceInfoOpen}>
                        <Modal.Header>Информация о высталеном счете</Modal.Header>
                        <Modal.Content>
                            <Dimmer inverted active={this.props.order.isInvoceLoading}>
                                <Loader size="medium">Loading</Loader>
                            </Dimmer>
                            Статус: {this.props.order.invoce.status}
                            <br />
                            Ссылка для оплаты:{" "}
                            {
                                <Link to={this.props.order.invoce.externalUrl}>
                                    {this.props.order.invoce.externalUrl}
                                </Link>
                            }
                            <br />
                        </Modal.Content>
                        <Modal.Actions>
                            <Button primary onClick={this.handleCloseModalInvoceInfo}>
                                Закрыть
                            </Button>
                        </Modal.Actions>
                    </Modal>
                </Grid.Row>
            </Grid>
        );
    }
}

function mapStateToProps(state) {
    return {
        companies: state.companyList,
        order: state.order,
        orderStatusList: state.orderStatusList,
        tagList: state.tagList,
        paymentMethodList: state.paymentMethodList,
    };
}

export const OrderDetailContainer = connect(mapStateToProps, {
    ...actionCreators,
    initPaymentMethodList,
})(OrderDetail);
